import styles from "./StockCard.module.css";
import Skeleton from "react-loading-skeleton";
import { useState } from "react";

import classNames from "classnames";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../routes/routes";

import { statuses } from "../../helpers/statusesStock";

export const StockCard = ({ data }) => {
    const navigate = useNavigate();
    const { auth, moderator } = useSelector((store) => store.user);
    const [loading, setLoading] = useState(true);
    const { id, discount_photo, discount_value, name, discount_type, status } =
        data;
    const statusesColors = {
        green: statuses.published,
        orange: statuses.unPublished,
        gray: statuses.archive,
    };

    return (
        <div
            className={classNames(styles.stockCard, styles.stockCardHover)}
            onClick={() => navigate(`${ROUTE.STOCK}/${id}`)}
        >
            {moderator && (
                <div
                    className={classNames(
                        styles.status,
                        status === statusesColors.green && styles.statusGreen,
                        status === statusesColors.orange && styles.statusOrange,
                        status === statusesColors.gray && styles.statusGray
                    )}
                >
                    {status}
                </div>
            )}
            <div className={styles.imageBlock}>
                {loading && (
                    <div className={styles.skeletonBlock}>
                        <Skeleton className={styles.skeleton} duration={0.5} />
                    </div>
                )}

                <img
                    onLoad={() => setLoading(false)}
                    src={`${process.env.REACT_APP_MEDIA_URL}${discount_photo}`}
                    alt="stock image"
                    className={styles.image}
                />
                <div className={styles.discount}>
                    {discount_type === "Бесплатное посещение" ? (
                        <p className={styles.percent}>Бесплатное посещение</p>
                    ) : (
                        <p className={styles.percent}>
                            {discount_value}
                            {discount_type === "Процентная" && "%"}
                            {discount_type === "Фиксированная" && "₽"}
                        </p>
                    )}
                </div>
            </div>
            <div className={styles.description}>{name}</div>
        </div>
    );
};
