import styles from "./Header.module.css";

import Logo from "../../../assets/images/logo.png";
import Logout from "../../../assets/images/logout.png";

import { Button } from "../Button";

import { useDispatch, useSelector } from "react-redux";
import { setOpenModalLogin } from "../../../redux/widgetsReducer";
import { logoutUser } from "../../../redux/userReducer";

import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../routes/routes";

export const Header = () => {
    const navigate = useNavigate();
    const { auth, data, moderator, consumer } = useSelector(
        (store) => store.user
    );
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(logoutUser());
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.removeItem("role");
    };
    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <img
                    src={Logo}
                    alt="logo"
                    className={styles.logo}
                    onClick={() => navigate(ROUTE.MAIN)}
                />
                <h1 className={styles.title}>
                    Промокоды и секретные <br /> акции специально
                    <br /> для <br />
                    клиентов ИнТелКом
                </h1>
                {moderator && data ? (
                    <div className={styles.authBlock}>
                        {data && (
                            <Button disabled className={styles.authInfo}>
                                {data?.role}
                                <br />
                                <span className={styles.email}>
                                    {data?.email}
                                </span>
                            </Button>
                        )}
                        <img
                            src={Logout}
                            alt="logout"
                            className={styles.logout}
                            onClick={logout}
                        />
                    </div>
                ) : auth === false ? (
                    <Button
                        className={styles.button}
                        onClick={() => dispatch(setOpenModalLogin(true))}
                    >
                        Вход
                    </Button>
                ) : consumer && auth === true ? (
                    <div className={styles.authBlock}>
                        <img
                            src={Logout}
                            alt="logout"
                            className={styles.logout}
                            onClick={logout}
                        />
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </header>
    );
};
