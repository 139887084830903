import { useEffect, useState } from "react";
import styles from "./UserForm.module.css";

import { Formik, Form } from "formik";
import { Input } from "../../UI/Input";
import { Button } from "../../UI/Button";

import { addUserSchema } from "../../../helpers/validations/addUserSchema";
import { editUserSchema } from "../../../helpers/validations/editUserSchema";
import { roles } from "../../../helpers/roles";
import {
    getUserByID,
    postAddUser,
    patchUser,
    changePassword,
} from "../../../api/requests";

import { useDispatch, useSelector } from "react-redux";
import {
    setModalFormUserID,
    setOpenModalFormUser,
} from "../../../redux/widgetsReducer";
import { mergeMessage } from "../../../helpers/mergeMessage";

export const UserForm = ({ update }) => {
    const id = useSelector((store) => store.widgets.modalFormUserID);
    const [user, setUser] = useState();
    const dispatch = useDispatch();
    const initialValues = {
        full_name: user ? user.full_name : "",
        email: user ? user.email : "",
        password: "",
        role: roles.moderator,
    };
    const handleSubmit = (values, { setFieldError }) => {
        if (id) {
            const oldValues = { ...values };
            const newValues = Object.entries(oldValues).reduce(
                (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                {}
            );
            let password = false;
            if (newValues?.password) {
                password = newValues?.password;
                delete newValues.password;
            }
            delete newValues.role;
            patchUser(id, newValues)
                .then(() => {
                    if (password) {
                        changePassword({
                            user_id: id,
                            new_password: password,
                            new_password_repeat: password,
                        })
                            .then(() => {
                                update();
                                dispatch(setOpenModalFormUser(false));
                                dispatch(setModalFormUserID(false));
                            })
                            .catch((errors) => {
                                setFieldError("password", mergeMessage(errors));
                            });
                    } else {
                        update();
                        dispatch(setOpenModalFormUser(false));
                        dispatch(setModalFormUserID(false));
                    }
                })
                .catch((errors) => {
                    Object.entries(errors).map(([key, value]) => {
                        setFieldError(key, value.join("\n "));
                    });
                });
        } else {
            postAddUser(values)
                .then(() => {
                    update();
                    dispatch(setOpenModalFormUser(false));
                })
                .catch((errors) => {
                    Object.entries(errors).map(([key, value]) => {
                        setFieldError(key, value.join("\n "));
                    });
                });
        }
    };
    useEffect(() => {
        if (id) {
            getUserByID(id).then((data) => setUser(data));
        }
    }, [id]);
    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={id ? editUserSchema : addUserSchema}
            enableReinitialize
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
            }) => {
                return (
                    <Form
                        className={styles.form}
                        onSubmit={handleSubmit}
                        autoComplete="none"
                    >
                        <Input
                            id="full_name_add_user"
                            autoComplete="none"
                            name={"full_name"}
                            value={values.full_name}
                            label={"Введите ФИО"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.full_name && errors.full_name
                                    ? errors.full_name
                                    : false
                            }
                        />
                        <Input
                            id="email_add_user"
                            autoComplete="none"
                            name={"email"}
                            value={values.email}
                            label={"Введите электронную почту"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.email && errors.email
                                    ? errors.email
                                    : false
                            }
                        />
                        <Input
                            id="password_add_user"
                            autoComplete="none"
                            type={"password"}
                            name={"password"}
                            value={values.password}
                            label={"Введите пароль"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.password && errors.password
                                    ? errors.password
                                    : false
                            }
                        />
                        <Button type="submit">
                            {id ? "Сохранить" : "Добавить"}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};
