import styles from "./InputMask.module.css";
import classnames from "classnames";

import InputMaskLib from "react-input-mask";

export const InputMask = ({
    mask,
    className,
    containerClassName,
    theme,
    label,
    error,
    ...props
}) => {
    const themes = {
        blue: "blue",
    };
    return (
        <div className={classnames(styles.container, containerClassName)}>
            {label && <label className={styles.label}>{label}</label>}
            <InputMaskLib
                mask={mask}
                className={classnames(
                    className,
                    styles.input,
                    theme === themes.blue && styles.inputBlue
                )}
                {...props}
            />
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};
