import { Modal } from "../UI/Modal";
import { LoginForm } from "../forms/LoginForm";

import { useSelector, useDispatch } from "react-redux";
import { setOpenModalLogin } from "../../redux/widgetsReducer";

export const LoginModal = () => {
    const open = useSelector((store) => store.widgets.openModalLogin);
    const dispatch = useDispatch();
    return (
        <Modal open={open} onClose={() => dispatch(setOpenModalLogin(false))}>
            <LoginForm />
        </Modal>
    );
};
