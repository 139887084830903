import { useEffect, useState } from "react";
import styles from "./Users.module.css";

import { getUsers, deleteUser } from "../../api/requests";

import { Button } from "../../components/UI/Button";
import { UserModal } from "../../components/UserModal";

import { useDispatch } from "react-redux";
import {
    setOpenModalFormUser,
    setModalFormUserID,
} from "../../redux/widgetsReducer";

import { roles } from "../../helpers/roles";

export const Users = () => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const update = () => {
        getUsers({ role__in: `${roles.moderator},${roles.admin}` }).then(
            (data) => setUsers(data)
        );
    };
    const handleDelete = (id) => {
        deleteUser(id).then(() => {
            update();
        });
    };
    useEffect(() => {
        update();
    }, []);
    return (
        <>
            <section className={styles.container}>
                <div className={styles.tableBlock}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>ФИО</th>
                                <th>Email</th>
                                <th>Роль</th>
                                <th>Изменить</th>
                                <th>Удалить</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr>
                                    <td>{user.full_name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        <Button
                                            className={styles.password}
                                            theme={"orange"}
                                            onClick={() => {
                                                dispatch(
                                                    setOpenModalFormUser(true)
                                                );
                                                dispatch(
                                                    setModalFormUserID(user.pk)
                                                );
                                            }}
                                        >
                                            Изменить
                                        </Button>
                                    </td>
                                    <td>
                                        {user.role !== roles.admin && (
                                            <Button
                                                className={styles.delete}
                                                theme={"blue"}
                                                onClick={() =>
                                                    handleDelete(user.pk)
                                                }
                                            >
                                                Удалить
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Button
                    theme={"blue"}
                    onClick={() => dispatch(setOpenModalFormUser(true))}
                >
                    Добавить менеджера
                </Button>
            </section>
            <UserModal update={update} />
        </>
    );
};
