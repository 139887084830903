import { createStore, combineReducers } from "redux";

import { widgetsReducer } from "./widgetsReducer";
import { userReducer } from "./userReducer";

import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
    widgets: widgetsReducer,
    user: userReducer,
});

export const store = createStore(rootReducer, composeWithDevTools());
