export const mergeMessage = (errorsObject) => {
    if (typeof errorsObject === "object") {
        return Object.entries(errorsObject)
            .map(([key, value]) => `${String(value)}`)
            .join("\n ");
        // .replace(/\./gi, "");
    } else {
        return "Что-то пошло не так";
    }
};
