import { Modal } from "../UI/Modal";

import { useSelector, useDispatch } from "react-redux";
import {
    setOpenModalFormUser,
    setModalFormUserID,
} from "../../redux/widgetsReducer";

import { UserForm } from "../forms/UserForm";

export const UserModal = ({ update }) => {
    const open = useSelector((store) => store.widgets.openModalFormUser);
    const dispatch = useDispatch();
    return (
        <Modal
            open={open}
            onClose={() => {
                dispatch(setOpenModalFormUser(false));
                dispatch(setModalFormUserID(false));
            }}
        >
            <UserForm update={update} />
        </Modal>
    );
};
