import { useState, useEffect } from "react";

import styles from "./Statistics.module.css";
import classNames from "classnames";

import Skeleton from "react-loading-skeleton";
import { MdOutlineArrowDropDown } from "react-icons/md";

import { getStocks, postStatistics } from "../../api/requests";
import { statuses } from "../../helpers/statusesStock";

export const Statistics = () => {
    const [publishedStocks, setPublishedStocks] = useState([]);
    const [unPublishedStocks, setUnPublishedStocks] = useState([]);
    const [archiveStocks, setArchiveStocks] = useState([]);
    const [activeStock, setActiveStock] = useState(false);
    const [statistics, setStatistics] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        getStocks({
            status: statuses.published,
        }).then((data) => setPublishedStocks(data));
        getStocks({
            status: statuses.unPublished,
        }).then((data) => setUnPublishedStocks(data));
        getStocks({
            status: statuses.archive,
        }).then((data) => setArchiveStocks(data));
    }, []);
    useEffect(() => {
        if (activeStock) {
            setLoading(true);
            postStatistics(activeStock)
                .then((data) => setStatistics(data))
                .catch(() => setStatistics(false))
                .finally(() => setLoading(false));
        }
    }, [activeStock]);
    return (
        <section className={styles.container}>
            <p className={styles.title}>Выгрузить статистику:</p>
            <div className={styles.selector}>
                <p
                    className={styles.open}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    Выберите акцию
                    <MdOutlineArrowDropDown
                        size={32}
                        style={{
                            transform: open ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                    />
                </p>
                {open && (
                    <>
                        <p className={styles.status}>{statuses.published}</p>
                        <div className={styles.list}>
                            {publishedStocks.map((stock) => (
                                <p
                                    className={classNames(
                                        styles.stock,
                                        activeStock === stock.id &&
                                            styles.stockActive
                                    )}
                                    onClick={() => setActiveStock(stock.id)}
                                >
                                    {stock.name}
                                </p>
                            ))}
                        </div>
                        <p className={styles.status}>{statuses.unPublished}</p>
                        <div className={styles.list}>
                            {unPublishedStocks.map((stock) => (
                                <p
                                    className={classNames(
                                        styles.stock,
                                        activeStock === stock.id &&
                                            styles.stockActive
                                    )}
                                    onClick={() => setActiveStock(stock.id)}
                                >
                                    {stock.name}
                                </p>
                            ))}
                        </div>
                        <p className={styles.status}>{statuses.archive}</p>
                        <div className={styles.list}>
                            {archiveStocks.map((stock) => (
                                <p
                                    className={classNames(
                                        styles.stock,
                                        activeStock === stock.id &&
                                            styles.stockActive
                                    )}
                                    onClick={() => setActiveStock(stock.id)}
                                >
                                    {stock.name}
                                </p>
                            ))}
                        </div>
                    </>
                )}
            </div>
            {loading ? (
                <div className={styles.statistics}>
                    <Skeleton className={styles.skeleton} duration={0.5} />
                    <Skeleton className={styles.skeleton} duration={0.5} />
                </div>
            ) : (
                statistics && (
                    <div className={styles.statistics}>
                        <div className={styles.statisticsItem}>
                            <p className={styles.itemName}>
                                Количество сгенерированных купонов
                            </p>
                            <p className={styles.itemValue}>
                                {statistics.generated}
                            </p>
                        </div>
                        <div className={styles.statisticsItem}>
                            <p className={styles.itemName}>
                                Количество активированных купонов
                            </p>
                            <p className={styles.itemValue}>
                                {statistics.activated}
                            </p>
                        </div>
                    </div>
                )
            )}
        </section>
    );
};
