import { Modal } from "../UI/Modal";

import styles from "./MessageModal.module.css";

import { useSelector, useDispatch } from "react-redux";
import { setModalMessage } from "../../redux/widgetsReducer";

export const MessageModal = () => {
    const message = useSelector((store) => store.widgets.modalMessage);
    const dispatch = useDispatch();
    return (
        <Modal open={message} onClose={() => dispatch(setModalMessage(false))}>
            <div className={styles.message}>{message}</div>
        </Modal>
    );
};
