const arrayCouponText = ["купон", "купона", "купонов"];

export const declOfNum = (number, arrayRemainedText) => {
    let n = Math.abs(number) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) {
        return `${arrayRemainedText[2]} ${number} ${arrayCouponText[2]}`;
    }
    if (n1 > 1 && n1 < 5) {
        return `${arrayRemainedText[1]} ${number} ${arrayCouponText[1]}`;
    }
    if (n1 == 1) {
        return `${arrayRemainedText[0]} ${number} ${arrayCouponText[0]}`;
    }
    return `${arrayRemainedText[2]} ${number} ${arrayCouponText[2]}`;
};
