import styles from "./StockCouponGeneration.module.css";

import { Button } from "../UI/Button";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setModalMessage, setModalCouponId } from "../../redux/widgetsReducer";

import { postAvailableCoupons } from "../../api/requests";
import { declOfNum } from "../../helpers/declOfNum";

export const StockCouponGeneration = ({ stock }) => {
    const dispatch = useDispatch();
    const { data: user, auth } = useSelector((store) => store.user);
    const [numberOfCoupons, setNumberOfCoupons] = useState(null);
    const generateText = (type, value) => {
        switch (type) {
            case "Бесплатное посещение":
                return "Купон на бесплатное посещение";
            case "Процентная":
                return `Купон на скидку ${value}%`;
            case "Фиксированная":
                return `Купон на сумму ${value}₽`;
        }
    };
    const handleGenerate = () => {
        if (auth) {
            dispatch(setModalCouponId(stock.id));
        } else {
            dispatch(
                setModalMessage(
                    "Перейдите в акцию из своего личного кабинета Интелком."
                )
            );
        }
    };
    useEffect(() => {
        if (user?.id && stock?.id) {
            postAvailableCoupons({
                discount_id: stock?.id,
                user_id: user?.id,
            })
                .then(({ count }) => {
                    setNumberOfCoupons(count);
                })
                .catch(() => {
                    setNumberOfCoupons(null);
                });
        }
    }, [user]);
    useEffect(() => {
        if (auth === false) {
            setNumberOfCoupons(null);
        }
    }, [auth]);
    return (
        <div className={styles.coupon}>
            <p className={styles.value}>
                {generateText(stock.discount_type, stock.discount_value)}
            </p>
            <Button
                disabled={
                    auth
                        ? numberOfCoupons === 0 || numberOfCoupons === null
                        : false
                }
                theme={"orange"}
                className={styles.button}
                onClick={handleGenerate}
            >
                Сгенерировать <br className={styles.br} /> купон
            </Button>
            {numberOfCoupons !== null && (
                <p className={styles.numberOfCoupons}>
                    Вам{" "}
                    {declOfNum(numberOfCoupons, [
                        "доступен",
                        "доступны",
                        "доступны",
                    ])}
                </p>
            )}
        </div>
    );
};
