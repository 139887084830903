import styles from "./Tabs.module.css";

import classNames from "classnames";

export const Tabs = ({ className, value, onChange, items }) => {
    return (
        <div className={classNames(styles.tabs, className)}>
            {items?.map((item) => (
                <button
                    key={item.id}
                    className={classNames(
                        styles.tab,
                        value === item.key && styles.tabActive
                    )}
                    onClick={() => onChange(item.key)}
                >
                    {item.name}
                </button>
            ))}
        </div>
    );
};
