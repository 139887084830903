import styles from "./Sidebar.module.css";

import { Button } from "../Button";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../routes/routes";

export const Sidebar = () => {
    const navigate = useNavigate();
    const admin = useSelector((store) => store.user.admin);
    return (
        <div className={styles.sidebar}>
            <div className={styles.menu}>
                <Button onClick={() => navigate(ROUTE.MAIN)}>На главную</Button>
                <Button onClick={() => navigate(ROUTE.ADD_STOCK)}>
                    Добавить акцию
                </Button>
                <Button onClick={() => navigate(ROUTE.STATISTICS)}>
                    Статистика
                </Button>
                {admin && (
                    <Button onClick={() => navigate(ROUTE.USERS)}>
                        Управление <br /> менеджерами
                    </Button>
                )}
            </div>
        </div>
    );
};
