import styles from "./StockMenu.module.css";

import { Button } from "../../components/UI/Button";

import { statuses } from "../../helpers/statusesStock";
import { patchStock } from "../../api/requests";

import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../routes/routes";

export const StockMenu = ({ stock, setStock }) => {
    const navigate = useNavigate();
    const publishStock = (id) => {
        patchStock(id, { status: statuses.published }).then((data) => {
            setStock(data);
        });
    };
    const unPublishStock = (id) => {
        patchStock(id, { status: statuses.unPublished }).then((data) => {
            setStock(data);
        });
    };
    const archiveStock = (id) => {
        patchStock(id, { status: statuses.archive }).then((data) => {
            setStock(data);
        });
    };
    return (
        <div className={styles.menu}>
            <p className={styles.status}>{stock.status}</p>
            <div className={styles.body}>
                {stock.status !== statuses.published ? (
                    <Button
                        disabled={stock.status === statuses.archive}
                        onClick={() => publishStock(stock.id)}
                    >
                        Опубликовать
                    </Button>
                ) : (
                    <Button onClick={() => unPublishStock(stock.id)}>
                        Снять с публикации
                    </Button>
                )}
                {stock.status !== statuses.archive ? (
                    <Button onClick={() => archiveStock(stock.id)}>
                        В архив
                    </Button>
                ) : (
                    <Button onClick={() => unPublishStock(stock.id)}>
                        Вернуть из архива
                    </Button>
                )}
                <Button
                    onClick={() => navigate(`${ROUTE.EDIT_STOCK}/${stock.id}`)}
                >
                    Редактировать
                </Button>
            </div>
        </div>
    );
};

export default StockMenu;
