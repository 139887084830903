import styles from "./Map.module.css";

import { useState, useEffect, useRef } from "react";
import { YMaps, Map as MapYandex, Placemark } from "react-yandex-maps";

import classNames from "classnames";

export const Map = ({ className, center, places }) => {
    const [bounds, setBounds] = useState(null);
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setBounds(bounds, { checkZoomRange: true });
        }
    }, [bounds]);
    const mapRef = useRef(null);
    return (
        <div className={classNames(styles.container, className)}>
            <YMaps className={styles.map}>
                <MapYandex
                    modules={[
                        "multiRouter.MultiRoute",
                        "coordSystem.geo",
                        "geocode",
                        "util.bounds",
                    ]}
                    onLoad={(ymaps) => {
                        if (places?.length > 0) {
                            const points = [];
                            places.map(({ address }) => {
                                points.push([
                                    address.latitude,
                                    address.longitude,
                                ]);
                            });
                            setBounds(ymaps.util.bounds.fromPoints(points));
                        }
                    }}
                    instanceRef={(ref) => {
                        if (ref) {
                            mapRef.current = ref;
                        }
                    }}
                    defaultState={{ center: [55.75, 37.57], zoom: 9 }}
                    className={styles.mapY}
                    state={{
                        center: [
                            center?.latitude ? center.latitude : 55.75,
                            center?.longitude ? center.longitude : 37.57,
                        ],
                        zoom: center?.latitude && center.longitude ? 16 : 9,
                    }}
                >
                    {places.map(({ address }) => (
                        <Placemark
                            geometry={[address?.latitude, address?.longitude]}
                        />
                    ))}
                </MapYandex>
            </YMaps>
        </div>
    );
};
