import { useEffect, useState } from "react";
import { Modal } from "../UI/Modal";
import styles from "./CouponModal.module.css";

import { useDispatch, useSelector } from "react-redux";
import { setModalCouponId } from "../../redux/widgetsReducer";

import { postGenerateQRCode, postAvailableCoupons } from "../../api/requests";
import { mergeMessage } from "../../helpers/mergeMessage";

import { declOfNum } from "../../helpers/declOfNum";

export const CouponModal = () => {
    const dispatch = useDispatch();
    const id = useSelector((store) => store.widgets.modalCouponId);
    const user = useSelector((store) => store.user.data);
    const [coupon, setCoupon] = useState(null);
    const [error, setError] = useState(null);
    const [numberOfCoupons, setNumberOfCoupons] = useState(null);
    useEffect(() => {
        if (id) {
            postGenerateQRCode(id)
                .then((data) => {
                    setCoupon(data);
                    postAvailableCoupons({
                        discount_id: id,
                        user_id: user?.id,
                    })
                        .then(({ count }) => {
                            setNumberOfCoupons(count);
                        })
                        .catch((error) => {
                            setNumberOfCoupons(null);
                            setError(mergeMessage(error));
                        });
                })
                .catch((error) => {
                    setError(mergeMessage(error));
                });
        }
    }, [id]);
    const onClose = () => {
        dispatch(setModalCouponId(false));
        setCoupon(null);
        setNumberOfCoupons(null);
        setError(null);
    };
    return (
        <Modal open={id !== false} onClose={onClose}>
            <div className={styles.coupon}>
                {error ? (
                    <p className={styles.error}>{error}</p>
                ) : coupon !== null && numberOfCoupons !== null ? (
                    <div className={styles.content}>
                        <p className={styles.warn}>
                            Если вы сами отсканируете купон, он потеряет свое
                            действие
                        </p>
                        <img
                            className={styles.qrCode}
                            src={`${process.env.REACT_APP_MEDIA_URL}${coupon.path}`}
                            alt="qr code coupon"
                        />
                        <p className={styles.numberOfCoupons}>
                            У вас{" "}
                            {declOfNum(numberOfCoupons, [
                                "остался",
                                "осталось",
                                "осталось",
                            ])}
                        </p>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </Modal>
    );
};
