import styles from "./Stocks.module.css";

import { useEffect, useState } from "react";

import { getStocks } from "../../api/requests";
import { useSelector } from "react-redux";

import { StockCard } from "../StockCard";
import { StockFilter } from "../StockFilter/StockFilter";

export const Stocks = () => {
    const { auth, moderator } = useSelector((store) => store.user);
    const [status, setStatus] = useState(false);
    const [stocks, setStocks] = useState([]);
    useEffect(() => {
        if (auth !== "first") {
            getStocks().then((data) => setStocks(data));
        }
    }, [auth]);
    useEffect(() => {
        if (status) {
            getStocks({ status }).then((data) => setStocks(data));
        } else {
            getStocks().then((data) => setStocks(data));
        }
    }, [status]);
    return (
        <section className={styles.stocks}>
            <div className={styles.container}>
                {moderator && (
                    <StockFilter
                        value={status}
                        onChange={(event) => setStatus(event.target.value)}
                    />
                )}

                <div className={styles.body}>
                    {stocks.map((stock) => (
                        <StockCard key={stock.id} data={stock} />
                    ))}
                </div>
            </div>
        </section>
    );
};
