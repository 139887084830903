import styles from "./LoginForm.module.css";

import { Formik, Form } from "formik";
import { Input } from "../../UI/Input";
import { Button } from "../../UI/Button";

import { loginSchema } from "../../../helpers/validations/loginSchema";

import { postLogin } from "../../../api/requests";

import { useDispatch } from "react-redux";
import { setAuth } from "../../../redux/userReducer";
import { setOpenModalLogin } from "../../../redux/widgetsReducer";

export const LoginForm = () => {
    const dispatch = useDispatch();
    const initialValues = {
        email: "",
        password: "",
    };
    const handleSubmit = async (values, { setFieldError }) => {
        postLogin(values)
            .then((data) => {
                localStorage?.setItem("access", data.access);
                localStorage?.setItem("refresh", data.refresh);
                dispatch(setOpenModalLogin(false));
                dispatch(setAuth(true));
            })
            .catch((errors) => {
                Object.entries(errors).map(([key, value]) => {
                    setFieldError(key, value.join("\n "));
                });
            });
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={loginSchema}
        >
            {({
                values,
                errors,
                handleSubmit,
                handleChange,
                handleBlur,
                isValid,
            }) => {
                const isValidForm =
                    !isValid ||
                    Object.values(values).filter(Boolean).length === 0;
                return (
                    <Form className={styles.form} onSubmit={handleSubmit}>
                        <p className={styles.title}>Вход</p>
                        <Input
                            className={styles.field}
                            placeholder="Введите свой логин"
                            theme="blue"
                            type="email"
                            name="email"
                            value={values.email}
                            error={errors.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <Input
                            className={styles.field}
                            placeholder="Пароль"
                            theme="blue"
                            type="password"
                            name="password"
                            value={values.password}
                            error={errors.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <Button
                            theme="blue"
                            className={styles.button}
                            disabled={isValidForm}
                        >
                            Войти
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};
