const defaultState = {
    openModalLogin: false,
    modalMessage: false,
    modalCouponId: false,
    openModalFormUser: false,
    modalFormUserID: false,
};

const SET_OPEN_MODAL_LOGIN = "SET_OPEN_MODAL_LOGIN";
const SET_MODAL_MESSAGE = "SET_MODAL_MESSAGE";
const SET_MODAL_COUPON_ID = "SET_MODAL_COUPON_ID";
const SET_OPEN_MODAL_FORM_USER = "SET_OPEN_MODAL_FORM_USER";
const SET_MODAL_FORM_USER_ID = "SET_MODAL_FORM_USER_ID";

export const widgetsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_OPEN_MODAL_LOGIN:
            return { ...state, openModalLogin: action.payload };
        case SET_MODAL_MESSAGE:
            return { ...state, modalMessage: action.payload };
        case SET_MODAL_COUPON_ID:
            return { ...state, modalCouponId: action.payload };
        case SET_OPEN_MODAL_FORM_USER:
            return { ...state, openModalFormUser: action.payload };
        case SET_MODAL_FORM_USER_ID:
            return { ...state, modalFormUserID: action.payload };
        default:
            return state;
    }
};

export const setOpenModalLogin = (payload) => ({
    type: SET_OPEN_MODAL_LOGIN,
    payload,
});

export const setModalMessage = (payload) => ({
    type: SET_MODAL_MESSAGE,
    payload,
});

export const setModalCouponId = (payload) => ({
    type: SET_MODAL_COUPON_ID,
    payload,
});

export const setOpenModalFormUser = (payload) => ({
    type: SET_OPEN_MODAL_FORM_USER,
    payload,
});

export const setModalFormUserID = (payload) => ({
    type: SET_MODAL_FORM_USER_ID,
    payload,
});
