import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ROUTE } from "./routes";

import { Layout } from "../components/UI/Layout";

import { Main } from "../pages/Main";
import { AddStock } from "../pages/AddStock";
import { EditStock } from "../pages/EditStock";
import { StockDetail } from "../pages/StockDetail";
import { Statistics } from "../pages/Statistics";
import { ActivateCoupon } from "../pages/ActivateCoupon";
import { Users } from "../pages/Users";
import { PageNotFound } from "../pages/PageNotFound";

import { useSelector } from "react-redux";
import { useEffect } from "react";

const privateRoutes = [
    ROUTE.STATISTICS.replace("/", ""),
    ROUTE.ADD_STOCK.replace("/", ""),
    ROUTE.EDIT_STOCK.replace("/", ""),
    ROUTE.USERS.replace("/", ""),
];

export const AuthRoutes = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        auth,
        data: user,
        admin,
        moderator,
    } = useSelector((store) => store.user);
    useEffect(() => {
        if (auth === false) {
            if (privateRoutes.includes(location.pathname.split("/")[1])) {
                navigate(ROUTE.MAIN);
            }
        }
    }, [auth]);
    return (
        <Layout>
            <Routes>
                <Route path={ROUTE.MAIN} element={<Main />} />
                <Route path={`${ROUTE.STOCK}/:id`} element={<StockDetail />} />
                {moderator && (
                    <>
                        <Route path={ROUTE.ADD_STOCK} element={<AddStock />} />
                        <Route
                            path={`${ROUTE.EDIT_STOCK}/:id`}
                            element={<EditStock />}
                        />
                        <Route
                            path={`${ROUTE.STOCK}/:id`}
                            element={<StockDetail />}
                        />
                        <Route
                            path={ROUTE.STATISTICS}
                            element={<Statistics />}
                        />
                    </>
                )}
                {admin && <Route path={ROUTE.USERS} element={<Users />} />}
                <Route
                    path={ROUTE.ACTIVATE_COUPON}
                    element={<ActivateCoupon />}
                />
                {auth === false || (auth === true && user) ? (
                    <Route path="*" element={<PageNotFound />} />
                ) : (
                    <></>
                )}
            </Routes>
        </Layout>
    );
};
