import * as yup from "yup";

const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
];

const reUrl =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const reTime = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/;

const rePhone = /\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/;

export const editStockSchema = yup.object().shape({
    name: yup.string().required("Обязательное поле"),
    image: yup.lazy((value) => {
        if (typeof value === "string") {
            return yup.string().required("Обязательное поле");
        } else {
            return yup
                .mixed()
                .nullable()
                .required("Обязательное поле")
                .test(
                    "FILE_FORMAT",
                    "Только форматы .jpg, .jpeg, .png, .webp",
                    (value) =>
                        !value ||
                        (value && SUPPORTED_FORMATS.includes(value.type))
                );
        }
    }),
    discount_photo: yup.lazy((value) => {
        if (typeof value === "string") {
            return yup.string().required("Обязательное поле");
        } else {
            return yup
                .mixed()
                .nullable()
                .required("Обязательное поле")
                .test(
                    "FILE_FORMAT",
                    "Только форматы .jpg, .jpeg, .png, .webp",
                    (value) =>
                        !value ||
                        (value && SUPPORTED_FORMATS.includes(value.type))
                );
        }
    }),
    description: yup.string().required("Обязательное поле"),
    text: yup.string().required("Обязательное поле"),
    addresses: yup
        .array()
        .of(
            yup.object().shape({
                point_name: yup.string().required("Обязательное поле"),
                address: yup.string().required("Обязательное поле"),
                latitude: yup.number().required("Обязательное поле"),
                longitude: yup.number().required("Обязательное поле"),
                work_time_from: yup
                    .string()
                    .required("Обязательное поле")
                    .matches(reTime, "Время некорректно"),
                work_time_to: yup
                    .string()
                    .required("Обязательное поле")
                    .matches(reTime, "Время некорректно"),
                phone_number: yup
                    .string()
                    .required("Обязательное поле")
                    .matches(rePhone, "Телефон некорректен"),
            })
        )
        .required("Required"),
    url: yup
        .string()
        .required("Обязательное поле")
        .matches(reUrl, "Ссылка некорректна"),
    discount_type: yup.string().required("Обязательное поле"),
    discount_value: yup
        .number()
        .when("discount_type", (discount_type, schema) => {
            if (discount_type === "Бесплатное посещение") {
                return schema;
            } else {
                return schema.required("Обязательное поле");
            }
        }),
    number_of_coupons: yup.number().required("Обязательное поле"),
    position: yup.number().required("Обязательное поле"),
});
