import { useEffect } from "react";

import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import { LoginModal } from "../../LoginModal";

import { getUser } from "../../../api/requests";
import { useDispatch, useSelector } from "react-redux";
import {
    setAuth,
    setUserData,
    setUserAdmin,
    setUserModerator,
    setUserConsumer,
} from "../../../redux/userReducer";

import { postValidateUser } from "../../../api/requests";
import { roles } from "../../../helpers/roles";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MessageModal } from "../../MessageModal";

export const Layout = ({ children }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const { auth, moderator } = useSelector((store) => store.user);
    useEffect(() => {
        if (
            localStorage.getItem("access") &&
            localStorage.getItem("role") !== roles.consumer
        ) {
            dispatch(setAuth(true));
        } else {
            const hash = searchParams.get("hash");
            const login = searchParams.get("login");
            if (login && hash) {
                postValidateUser({ login, hash })
                    .then((data) => {
                        localStorage?.setItem("access", data.access);
                        localStorage?.setItem("refresh", data.refresh);
                        dispatch(setAuth(true));
                    })
                    .catch(() => {
                        dispatch(setAuth(false));
                    })
                    .finally(() => {
                        setSearchParams("");
                    });
            } else {
                if (localStorage.getItem("access")) {
                    dispatch(setAuth(true));
                } else {
                    dispatch(setAuth(false));
                }
            }
        }
    }, []);
    useEffect(() => {
        if (auth === true) {
            getUser().then((data) => {
                dispatch(setUserData(data));
                localStorage.setItem("role", data.role);
                if (data?.role === roles.admin) {
                    dispatch(setUserAdmin(true));
                } else {
                    dispatch(setUserAdmin(false));
                }
                if (
                    data?.role === roles.moderator ||
                    data?.role === roles.admin
                ) {
                    dispatch(setUserModerator(true));
                } else {
                    dispatch(setUserModerator(false));
                }
                if (data?.role === roles.consumer) {
                    dispatch(setUserConsumer(true));
                } else {
                    dispatch(setUserConsumer(false));
                }
            });
        }
    }, [auth]);
    return (
        <>
            <Header />
            <div className="content">
                {moderator && <Sidebar />}
                {children}
                {auth === false && <LoginModal />}
            </div>
            <MessageModal />
        </>
    );
};
