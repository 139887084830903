import styles from "./PageNotFound.module.css";

export const PageNotFound = () => {
    return (
        <section className={styles.body}>
            <h1 className={styles.title}>404</h1>
            <p>Страница не найдена</p>
        </section>
    );
};
