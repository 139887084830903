import styles from "./Button.module.css";
import classnames from "classnames";

export const Button = ({ children, theme, className, ...props }) => {
    const themes = {
        blue: "blue",
        orange: "orange",
    };
    return (
        <button
            {...props}
            className={classnames(
                className,
                styles.button,
                theme === themes.blue && styles.buttonBlue,
                theme === themes.orange && styles.buttonOrange
            )}
        >
            {children}
        </button>
    );
};
