import styles from "./Modal.module.css";
import Close from "../../../assets/images/close.svg";
import { useEffect } from "react";

export const Modal = ({ open, onClose, children }) => {
    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [open]);
    return (
        open && (
            <div className={styles.modal} onClick={onClose}>
                <div
                    className={styles.body}
                    onClick={(e) => e.stopPropagation()}
                >
                    <img
                        src={Close}
                        alt="close"
                        className={styles.close}
                        onClick={onClose}
                    />
                    {children}
                </div>
            </div>
        )
    );
};
