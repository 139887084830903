import styles from "./StockForm.module.css";
import { useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { addStockSchema } from "../../../helpers/validations/addStockSchema";
import { editStockSchema } from "../../../helpers/validations/editStockSchema";

import { Input } from "../../UI/Input";
import { Uploader } from "../../UI/Uploader";
import { InputMask } from "../../UI/InputMask";
import { Wysiwyg } from "../../Wysiwyg";
import { Select } from "../../UI/Select";
import { Button } from "../../UI/Button";
import { Map } from "../../Map";

import Delete from "../../../assets/images/close.svg";

import {
    postStock,
    patchStock,
    postAddress,
    deleteAddress,
    patchAddress,
    addRelationAddressAndStock,
    getGeolocation,
} from "../../../api/requests";

import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../routes/routes";

import { statuses } from "../../../helpers/statusesStock";

const options = [
    { value: "Фиксированная", name: "Фиксированная" },
    { value: "Процентная", name: "Процентная" },
    { value: "Бесплатное посещение", name: "Бесплатное посещение" },
];

export const StockForm = ({ stock, id }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [stockID, setStockID] = useState(false);
    const initialValues = {
        name: "",
        image: null,
        discount_photo: null,
        description: "",
        text: "",
        addresses: [
            {
                id: false,
                point_name: "",
                address: "",
                latitude: false,
                longitude: false,
                work_time_from: "",
                work_time_to: "",
                phone_number: "",
            },
        ],
        url: "",
        discount_type: "",
        discount_value: "",
        number_of_coupons: "",
        position: "",
        status: statuses.unPublished,
    };
    const addressesSave = async ({
        addresses,
        setFieldValue,
        dataStock,
        setFieldError,
    }) => {
        // Если хотя бы один запрос не проходит переменная success становиться false
        let success = true;
        await Promise.all(
            addresses.map(async (address, index) => {
                const newAddress = { ...address };
                delete newAddress.id;
                // Если у адреса есть id, то он редактируется, иначе создаётся
                if (address.id) {
                    await patchAddress(address.id, newAddress)
                        .then((dataAddress) => {
                            setFieldValue(
                                `addresses[${index}].id`,
                                dataAddress.id
                            );
                        })
                        .catch((errors) => {
                            success = false;
                            Object.entries(errors).map(([key, value]) => {
                                setFieldError(
                                    `addresses[${index}].${key}`,
                                    value.join("\n ")
                                );
                            });
                        });
                } else {
                    await postAddress(newAddress)
                        .then((dataAddress) => {
                            setFieldValue(
                                `addresses[${index}].id`,
                                dataAddress.id
                            );
                            // Запрос на создание связи
                            addRelationAddressAndStock({
                                address_pk: dataAddress?.id,
                                discount_pk: dataStock?.id,
                            }).catch(() => {
                                success = false;
                            });
                        })
                        .catch((errors) => {
                            success = false;
                            Object.entries(errors).map(([key, value]) => {
                                setFieldError(
                                    `addresses[${index}].${key}`,
                                    value.join("\n ")
                                );
                            });
                        });
                }
            })
        );
        // Если success true, то редирект на ROUTE.MAIN
        setLoading(false);
        if (success) {
            if (stock) {
                navigate(-1);
            } else {
                navigate(ROUTE.MAIN);
            }
        }
    };

    const handleSubmit = async (values, { setFieldValue, setFieldError }) => {
        setLoading(true);
        // дубликат значений, чтобы из него удалить адреса не затронув состояние формика
        const newValues = { ...values };
        const addresses = newValues.addresses;
        delete newValues.addresses;
        // Пытался сделать очистку состояния если "Бесплатное" посещение, но не получилось очищаю таким образом
        if (newValues.discount_type === "Бесплатное посещение") {
            newValues.discount_value = null;
        }
        // Создаю и заполняю formData
        const formData = new FormData();
        Object.entries(newValues).map(([key, value]) => {
            formData.append(key, value);
        });
        // Если id есть то обновляю акцию, иначе создаю
        if (stockID) {
            if (typeof newValues.image === "string") {
                formData.delete("image");
            }
            if (typeof newValues.discount_photo === "string") {
                formData.delete("discount_photo");
            }
            patchStock(stockID, formData)
                .then((dataStock) => {
                    // Задаю id
                    setStockID(dataStock?.id);
                    // Отправляю адреса
                    addressesSave({
                        addresses,
                        setFieldValue,
                        dataStock,
                        setFieldError,
                    });
                })
                .catch((errors) => {
                    // Перебираю и заношу ошибки для акции
                    setLoading(false);
                    Object.entries(errors).map(([key, value]) => {
                        setFieldError(key, value.join("\n "));
                    });
                });
        } else {
            postStock(formData)
                .then((dataStock) => {
                    // Задаю id
                    setStockID(dataStock?.id);
                    // Отправляю адреса
                    addressesSave({
                        addresses,
                        setFieldValue,
                        dataStock,
                        setFieldError,
                    });
                })
                .catch((errors) => {
                    // Перебираю и заношу ошибки для акции
                    setLoading(false);
                    Object.entries(errors).map(([key, value]) => {
                        setFieldError(key, value.join("\n "));
                    });
                });
        }
    };
    useEffect(() => {
        if (id) {
            setStockID(id);
        }
    }, [id]);
    return (
        <Formik
            initialValues={stock ? stock : initialValues}
            onSubmit={handleSubmit}
            validationSchema={stock ? editStockSchema : addStockSchema}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                setFieldError,
                setFieldTouched,
                handleSubmit,
                handleBlur,
                submitCount,
            }) => {
                return (
                    <Form className={styles.form} onSubmit={handleSubmit}>
                        <div className={styles.body}>
                            <div className={styles.column}>
                                <Input
                                    label={"Введите название"}
                                    className={styles.field}
                                    containerClassName={styles.fieldContainer}
                                    name="name"
                                    value={values.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={
                                        touched.name && errors.name
                                            ? errors.name
                                            : false
                                    }
                                />
                                <Uploader
                                    label="Превью для главной страницы"
                                    containerClassName={styles.upload}
                                    accept=".jpg, .jpeg, .png, .webp"
                                    name="discount_photo"
                                    file={values.discount_photo}
                                    onChange={(event) => {
                                        setFieldValue(
                                            "discount_photo",
                                            event.currentTarget.files[0]
                                        );
                                    }}
                                    error={
                                        touched.discount_photo &&
                                        errors.discount_photo
                                            ? errors.discount_photo
                                            : false
                                    }
                                />
                                <Uploader
                                    label="Фотография для полной страницы акции"
                                    containerClassName={styles.upload}
                                    accept=".jpg, .jpeg, .png, .webp"
                                    name="image"
                                    file={values.image}
                                    onChange={(event) => {
                                        setFieldValue(
                                            "image",
                                            event.currentTarget.files[0]
                                        );
                                    }}
                                    error={
                                        touched.image && errors.image
                                            ? errors.image
                                            : false
                                    }
                                />
                                <div className={styles.wysiwyg}>
                                    <Wysiwyg
                                        label={"Описание"}
                                        value={values.description}
                                        onChange={(value) => {
                                            setFieldValue("description", value);
                                        }}
                                        onBlur={() =>
                                            setFieldTouched("description", true)
                                        }
                                        error={
                                            touched.description &&
                                            errors.description
                                                ? errors.description
                                                : false
                                        }
                                    />
                                </div>
                                <div className={styles.wysiwyg}>
                                    <Wysiwyg
                                        label={"Текст условия"}
                                        value={values.text}
                                        onChange={(value) => {
                                            setFieldValue("text", value);
                                        }}
                                        onBlur={() =>
                                            setFieldTouched("text", true)
                                        }
                                        error={
                                            touched.text && errors.text
                                                ? errors.text
                                                : false
                                        }
                                    />
                                </div>
                                <p>Адрес/Адреса</p>
                                {values?.addresses?.map((address, index) => (
                                    <>
                                        {values?.addresses?.length > 1 && (
                                            <img
                                                src={Delete}
                                                alt="delete"
                                                className={`${styles.delete} ${
                                                    loading
                                                        ? styles.deleteDisabled
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                    if (loading) return;
                                                    if (address.id) {
                                                        setLoading(true);
                                                        deleteAddress(
                                                            address.id
                                                        )
                                                            .then(() => {
                                                                const newAddresses =
                                                                    [
                                                                        ...values.addresses,
                                                                    ];
                                                                newAddresses.splice(
                                                                    index,
                                                                    1
                                                                );
                                                                setFieldValue(
                                                                    "addresses",
                                                                    newAddresses
                                                                );
                                                            })
                                                            .finally(() => {
                                                                setLoading(
                                                                    false
                                                                );
                                                            });
                                                    } else {
                                                        const newAddresses = [
                                                            ...values.addresses,
                                                        ];
                                                        newAddresses.splice(
                                                            index,
                                                            1
                                                        );
                                                        setFieldValue(
                                                            "addresses",
                                                            newAddresses
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                        <Input
                                            label="Название точки"
                                            className={styles.field}
                                            containerClassName={
                                                styles.fieldContainer
                                            }
                                            name={`addresses[${index}].point_name`}
                                            value={
                                                values.addresses[index]
                                                    .point_name
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                errors?.addresses?.length > 0 &&
                                                touched?.addresses?.length > 0
                                                    ? touched?.addresses[index]
                                                          ?.point_name &&
                                                      errors?.addresses[index]
                                                          ?.point_name
                                                        ? errors?.addresses[
                                                              index
                                                          ]?.point_name
                                                        : false
                                                    : false
                                            }
                                        />
                                        <Input
                                            label={"Адрес"}
                                            className={styles.field}
                                            containerClassName={
                                                styles.fieldContainer
                                            }
                                            name={`addresses[${index}].address`}
                                            value={
                                                values.addresses[index].address
                                            }
                                            onBlur={() => {
                                                setFieldTouched(
                                                    "addresses[${index}].address",
                                                    true
                                                );
                                                if (
                                                    values.addresses[index]
                                                        .address
                                                ) {
                                                    getGeolocation(
                                                        values.addresses[index]
                                                            .address
                                                    )
                                                        .then((data) => {
                                                            setFieldValue(
                                                                `addresses[${index}].address`,
                                                                data["Адрес"]
                                                            );
                                                            setFieldValue(
                                                                `addresses[${index}].latitude`,
                                                                data.Latitude
                                                            );
                                                            setFieldValue(
                                                                `addresses[${index}].longitude`,
                                                                data.Longitude
                                                            );
                                                            setFieldError(
                                                                `addresses[${index}].address`,
                                                                false
                                                            );
                                                        })
                                                        .catch(() => {
                                                            setFieldError(
                                                                `addresses[${index}].address`,
                                                                "Адрес не найден"
                                                            );
                                                        })
                                                        .finally(() => {
                                                            setLoading(false);
                                                        });
                                                }
                                            }}
                                            onChange={(event) => {
                                                setFieldValue(
                                                    `addresses[${index}].address`,
                                                    event.target.value
                                                );
                                                setLoading(true);
                                            }}
                                            error={
                                                errors?.addresses?.length > 0 &&
                                                touched?.addresses?.length > 0
                                                    ? touched?.addresses[index]
                                                          ?.address &&
                                                      errors?.addresses[index]
                                                          ?.address
                                                        ? errors?.addresses[
                                                              index
                                                          ]?.address
                                                        : false
                                                    : false
                                            }
                                        />
                                        <Map
                                            center={{
                                                latitude:
                                                    values.addresses[index]
                                                        .latitude,
                                                longitude:
                                                    values.addresses[index]
                                                        .longitude,
                                            }}
                                            places={
                                                values.addresses[index]
                                                    .latitude &&
                                                values.addresses[index]
                                                    .longitude
                                                    ? [
                                                          {
                                                              address: {
                                                                  latitude:
                                                                      values
                                                                          .addresses[
                                                                          index
                                                                      ]
                                                                          .latitude,
                                                                  longitude:
                                                                      values
                                                                          .addresses[
                                                                          index
                                                                      ]
                                                                          .longitude,
                                                              },
                                                          },
                                                      ]
                                                    : []
                                            }
                                        />
                                        <div className={styles.workingHours}>
                                            <InputMask
                                                label={"c"}
                                                mask="99:99"
                                                name={`addresses[${index}].work_time_from`}
                                                value={
                                                    values?.addresses[index]
                                                        ?.work_time_from
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    errors?.addresses?.length >
                                                        0 &&
                                                    touched?.addresses?.length >
                                                        0
                                                        ? touched?.addresses[
                                                              index
                                                          ]?.work_time_from &&
                                                          errors?.addresses[
                                                              index
                                                          ]?.work_time_from
                                                            ? errors?.addresses[
                                                                  index
                                                              ]?.work_time_from
                                                            : false
                                                        : false
                                                }
                                            />
                                            <InputMask
                                                label={"до"}
                                                mask="99:99"
                                                name={`addresses[${index}].work_time_to`}
                                                value={
                                                    values?.addresses[index]
                                                        ?.work_time_to
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    errors?.addresses?.length >
                                                        0 &&
                                                    touched?.addresses?.length >
                                                        0
                                                        ? touched?.addresses[
                                                              index
                                                          ]?.work_time_to &&
                                                          errors?.addresses[
                                                              index
                                                          ]?.work_time_to
                                                            ? errors?.addresses[
                                                                  index
                                                              ]?.work_time_to
                                                            : false
                                                        : false
                                                }
                                            />
                                        </div>
                                        <InputMask
                                            label={"Контактный телефон"}
                                            mask="+7(999)999-99-99"
                                            className={styles.field}
                                            containerClassName={
                                                styles.fieldContainer
                                            }
                                            name={`addresses[${index}].phone_number`}
                                            value={
                                                values.addresses[index]
                                                    .phone_number
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                errors?.addresses?.length > 0 &&
                                                touched?.addresses?.length > 0
                                                    ? touched?.addresses[index]
                                                          ?.phone_number &&
                                                      errors?.addresses[index]
                                                          ?.phone_number
                                                        ? errors?.addresses[
                                                              index
                                                          ]?.phone_number
                                                        : false
                                                    : false
                                            }
                                        />
                                    </>
                                ))}
                                <Button
                                    disabled={loading}
                                    type="button"
                                    theme={"blue"}
                                    onClick={() => {
                                        const newAddresses = [
                                            ...values.addresses,
                                            {
                                                id: false,
                                                address: "",
                                                latitude: "",
                                                longitude: "",
                                                work_time_from: "",
                                                work_time_to: "",
                                                phone_number: "",
                                            },
                                        ];
                                        setFieldValue(
                                            "addresses",
                                            newAddresses
                                        );
                                    }}
                                >
                                    Добавить адрес
                                </Button>
                                <Input
                                    label={"Ссылка на сайт партнера"}
                                    className={styles.field}
                                    containerClassName={styles.fieldContainer}
                                    type="url"
                                    name="url"
                                    value={values.url}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.url && errors.url
                                            ? errors.url
                                            : false
                                    }
                                />
                            </div>
                            <div className={styles.column}>
                                <Select
                                    label={"Тип скидки"}
                                    options={options}
                                    className={styles.field}
                                    containerClassName={styles.fieldContainer}
                                    name={"discount_type"}
                                    value={values.discount_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.discount_type &&
                                        errors.discount_type
                                            ? errors.discount_type
                                            : false
                                    }
                                />
                                <Input
                                    label={"Процент/колличество"}
                                    disabled={
                                        values.discount_type ===
                                        "Бесплатное посещение"
                                    }
                                    className={styles.field}
                                    containerClassName={styles.fieldContainer}
                                    type="number"
                                    name="discount_value"
                                    value={values.discount_value}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.discount_value &&
                                        errors.discount_value
                                            ? errors.discount_value
                                            : false
                                    }
                                />
                                <Input
                                    label={"Кол-во доступных купонов"}
                                    className={styles.field}
                                    containerClassName={styles.fieldContainer}
                                    type="number"
                                    name="number_of_coupons"
                                    value={values.number_of_coupons}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.number_of_coupons &&
                                        errors.number_of_coupons
                                            ? errors.number_of_coupons
                                            : false
                                    }
                                />
                                <Input
                                    label={"Номер баннера на главной"}
                                    className={styles.field}
                                    containerClassName={styles.fieldContainer}
                                    type="number"
                                    name="position"
                                    value={values.position}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.position && errors.position
                                            ? errors.position
                                            : false
                                    }
                                />
                            </div>
                        </div>
                        <Button
                            type="submit"
                            theme={"blue"}
                            className={styles.button}
                            disabled={loading}
                        >
                            Сохранить
                        </Button>
                        {Object.keys(errors).length > 0 && submitCount > 0 && (
                            <p className={styles.error}>Что-то пошло не так</p>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};
