import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./StockDetail.module.css";

import { useLocation, useNavigate } from "react-router-dom";

import { getStocksById } from "../../api/requests";

import { StockMenu } from "../../components/StockMenu";
import { Tabs } from "../../components/UI/Tabs";
import { StockAddresses } from "../../components/StockAddresses";
import { StockCouponGeneration } from "../../components/StockCouponGeneration";
import { CouponModal } from "../../components/CouponModal/CouponModal";

const interfaces = {
    description: "description",
    requirement: "requirement",
    addresses: "addresses",
};

const tabs = [
    { id: 1, name: "Описание", key: interfaces.description },
    { id: 2, name: "Условия", key: interfaces.requirement },
    { id: 3, name: "Адреса", key: interfaces.addresses },
];

export const StockDetail = () => {
    const [interfacePage, setInterfacePage] = useState(interfaces.description);
    const { moderator } = useSelector((store) => store.user);
    const navigate = useNavigate();
    const location = useLocation();
    const [stock, setStock] = useState(false);

    useEffect(() => {
        const id = location.pathname.replace("/stock/", "");
        getStocksById(id)
            .then((data) => {
                setStock(data);
            })
            .catch(() => {
                setStock(false);
                navigate("/404");
            });
    }, [location.pathname]);
    return (
        <>
            <section className={styles.container}>
                {stock && (
                    <>
                        <div className={styles.imageAndWidgets}>
                            <img
                                src={`${process.env.REACT_APP_MEDIA_URL}/${stock.image}`}
                                alt="stock image"
                                className={styles.image}
                            />
                            {moderator ? (
                                <StockMenu stock={stock} setStock={setStock} />
                            ) : (
                                <StockCouponGeneration stock={stock} />
                            )}
                        </div>
                        <Tabs
                            className={styles.tabs}
                            value={interfacePage}
                            onChange={setInterfacePage}
                            items={tabs}
                        />
                        <div className={styles.content}>
                            {interfacePage === interfaces.description && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: stock.description,
                                    }}
                                />
                            )}
                            {interfacePage === interfaces.requirement && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: stock.text,
                                    }}
                                />
                            )}
                            {interfacePage === interfaces.addresses && (
                                <StockAddresses
                                    addresses={stock.relation_discount}
                                    link={stock.url}
                                />
                            )}
                        </div>
                    </>
                )}
            </section>
            <CouponModal />
        </>
    );
};
