import axios from ".";

export const getStocks = async (params) => {
    try {
        const response = await axios.get("discounts/", { params });
        return response.data;
    } catch {
        return [];
    }
};

export const getStocksById = async (id) => {
    try {
        const response = await axios.get(`discounts/${id}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const postStock = async (body) => {
    try {
        const response = await axios.post("discounts/", body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const patchStock = async (id, body) => {
    try {
        const response = await axios.patch(`discounts/${id}/`, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const postLogin = async (body) => {
    try {
        const response = await axios.post("login/", body);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const postValidateUser = async (body) => {
    try {
        const response = await axios.post("validate-user/", body);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const getUser = async () => {
    try {
        const response = await axios.get("me/");
        return response.data;
    } catch {}
};

export const getUsers = async (params) => {
    try {
        const response = await axios.get("users/", { params });
        return response.data;
    } catch {}
};

export const getUserByID = async (id) => {
    try {
        const response = await axios.get(`user/${id}/`);
        return response.data;
    } catch {}
};

export const postAddUser = async (data) => {
    try {
        const response = await axios.post("create-user/", data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const patchUser = async (id, data) => {
    try {
        const response = await axios.patch(`user/${id}/`, data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const changePassword = async (data) => {
    try {
        const response = await axios.post("change-password/", data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const deleteUser = async (id) => {
    try {
        const response = await axios.delete(`user/${id}/`);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const getGeolocation = async (name) => {
    try {
        const response = await axios.post("get-geolocation/", { name });
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const postAddress = async (body) => {
    try {
        const response = await axios.post("address/", body);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const deleteAddress = async (id) => {
    try {
        const response = await axios.delete(`address/${id}/`);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const patchAddress = async (id, body) => {
    try {
        const response = await axios.patch(`address/${id}/`, body);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const addRelationAddressAndStock = async (body) => {
    try {
        const response = await axios.post("add-address/", body);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const postStatistics = async (id) => {
    try {
        const response = await axios.post("statistic/", { discount_id: id });
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const postAvailableCoupons = async (body) => {
    try {
        const response = await axios.post("available-coupons/", body);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const postGenerateQRCode = async (id) => {
    try {
        const response = await axios.post("generate-qr-code/", {
            discount_id: id,
        });
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const postActivateCoupon = async (data) => {
    try {
        const response = await axios.post("activate-coupon/", data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
};
