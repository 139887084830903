import { useEffect, useState } from "react";
import { Modal } from "../../components/UI/Modal";
import styles from "./ActivateCoupon.module.css";

import { useSearchParams, useNavigate } from "react-router-dom";
import { ROUTE } from "../../routes/routes";

import { Button } from "../../components/UI/Button";

import { postActivateCoupon } from "../../api/requests";
import { mergeMessage } from "../../helpers/mergeMessage";

export const ActivateCoupon = () => {
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const user_code = searchParams.get("user_code");
        const user_id = searchParams.get("user_id");
        const discount_id = searchParams.get("discount_id");
        const coupon_id = searchParams.get("coupon_id");
        if (user_code && user_id && discount_id && coupon_id) {
            setData({ user_code, user_id, discount_id, coupon_id });
        } else {
            setData(null);
        }
    }, []);
    const handleActivate = () => {
        setLoading(true);
        postActivateCoupon(data)
            .then(() => {
                setSuccess("Купон успешно активирован");
            })
            .catch((error) => {
                setError(mergeMessage(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            <Modal open={data !== null} onClose={() => navigate(ROUTE.MAIN)}>
                <div className={styles.activate}>
                    {success ? (
                        <p className={styles.success}>{success}</p>
                    ) : (
                        <>
                            <p className={styles.info}>
                                Спецпредложение для абонентов ИнТелКом
                            </p>
                            <Button
                                disabled={loading}
                                theme={"orange"}
                                className={styles.button}
                                onClick={handleActivate}
                            >
                                Активировать купон
                            </Button>
                            {error && <p className={styles.error}>{error}</p>}
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};
