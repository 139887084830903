import { useState } from "react";

import styles from "./EditStock.module.css";
import { IoChevronBackCircle } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";

import { StockForm } from "../../components/forms/StockForm";
import { useEffect } from "react";

import { getStocksById } from "../../api/requests";

export const EditStock = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [stock, setStock] = useState(false);
    const [stockID, setStockID] = useState();
    useEffect(() => {
        const id = +location.pathname.replace("/edit-stock/", "");
        getStocksById(id)
            .then((data) => {
                setStockID(data.id);
                data.addresses = [...data.relation_discount];
                const newAddresses = [];
                data.addresses.map((address) => {
                    newAddresses.push(address.address);
                });
                if (data.discount_value === "null") {
                    data.discount_value = "";
                }
                data.addresses = newAddresses;
                data.discount_photo = data.discount_photo.split("/").pop();
                data.image = data.image.split("/").pop();
                delete data.relation_discount;
                delete data.generated_coupons;
                delete data.id;
                setStock(data);
            })
            .catch(() => {
                setStock(false);
                navigate("/404");
            });
    }, [location.pathname]);
    return (
        <section className={styles.editStock}>
            <div className={styles.header}>
                <div className={styles.back} onClick={() => navigate(-1)}>
                    <span>
                        <IoChevronBackCircle className={styles.backIcon} />
                    </span>
                    Назад
                </div>
                <p className={styles.title}>Редактировать {stock.name}</p>
            </div>
            {stock && <StockForm stock={stock} id={stockID} edit={true} />}
        </section>
    );
};
