import styles from "./Select.module.css";

import classNames from "classnames";

export const Select = ({
    className,
    containerClassName,
    label,
    error,
    options,
    ...props
}) => {
    return (
        <div className={classNames(styles.container, containerClassName)}>
            {label && <label className={styles.label}>{label}</label>}
            <select className={classNames(styles.select, className)} {...props}>
                <option value=""></option>
                {options?.map((item, index) => (
                    <option key={index} value={item?.value}>
                        {item.name}
                    </option>
                ))}
            </select>
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};
