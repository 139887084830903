import styles from "./Uploader.module.css";

import classNames from "classnames";

export const Uploader = ({
    className,
    containerClassName,
    label,
    error,
    accept,
    name,
    file,
    onChange,
}) => {
    const correctionFileName = (name) => {
        if (name.length > 18) {
            return `${name.slice(0, 18)}...`;
        } else {
            return name;
        }
    };
    return (
        <div className={classNames(styles.container, containerClassName)}>
            {label && <label className={styles.label}>{label}</label>}
            <input
                type="file"
                id={`file_${name}`}
                className={styles.input}
                onChange={onChange}
                accept={accept}
            />
            <label
                for={`file_${name}`}
                className={classNames(styles.upload, className)}
            >
                <span
                    theme={"blue"}
                    className={styles.button}
                    type="button"
                    component="span"
                >
                    Выберите файл
                </span>
                {typeof file === "string"
                    ? correctionFileName(file)
                    : file
                    ? correctionFileName(file?.name)
                    : "Файл не выбран"}
            </label>
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};
