import axios from "axios";

import { store } from "../redux";

const API_URL = `${process.env.REACT_APP_SERVER_URL}`;

const defaultOptions = {
    baseURL: API_URL,
};

const ignoreAfterLogout = ["me/", "discounts/"];

let refreshPromise = false;

export default (function () {
    const instance = axios.create(defaultOptions);

    instance.interceptors.request.use((config) => {
        if (localStorage.getItem("access")) {
            config.headers.Authorization = `Bearer ${localStorage.getItem(
                "access"
            )}`;
        }
        return config;
    });

    instance.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;
            if (
                error?.response?.status === 401 &&
                error?.config &&
                !error?.config._isRetry
            ) {
                originalRequest._isRetry = true;
                if (!refreshPromise) {
                    refreshPromise = axios.post(`${API_URL}token-refresh/`, {
                        refresh: localStorage.getItem("refresh"),
                    });
                }
                try {
                    const response = await refreshPromise;
                    localStorage?.setItem("access", response.data.access);
                    localStorage?.setItem("refresh", response.data.refresh);
                    return instance.request(originalRequest);
                } catch (error) {
                    localStorage.removeItem("access");
                    localStorage.removeItem("refresh");
                    localStorage.removeItem("role");
                    store.dispatch({ type: "LOGOUT_USER" });
                    delete originalRequest.headers.Authorization;
                    if (ignoreAfterLogout.includes(originalRequest.url)) {
                        return;
                    } else {
                        return instance.request(originalRequest);
                    }
                } finally {
                    refreshPromise = false;
                }
            } else {
                throw error;
            }
        }
    );

    return instance;
})();
