import styles from "./AddStock.module.css";
import { IoChevronBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { StockForm } from "../../components/forms/StockForm";

export const AddStock = () => {
    const navigate = useNavigate();
    return (
        <section className={styles.addStock}>
            <div className={styles.header}>
                <div className={styles.back} onClick={() => navigate(-1)}>
                    <span>
                        <IoChevronBackCircle className={styles.backIcon} />
                    </span>
                    Назад
                </div>

                <p className={styles.title}>Добавить акцию</p>
            </div>
            <StockForm />
        </section>
    );
};
