import styles from "./StockFilter.module.css";
import { Select } from "../UI/Select";

import { statuses } from "../../helpers/statusesStock";

const options = [
    { value: statuses.published, name: statuses.published },
    { value: statuses.unPublished, name: statuses.unPublished },
    { value: statuses.archive, name: statuses.archive },
];

export const StockFilter = ({ value, onChange }) => {
    return (
        <Select
            label="Фильтр по статусу"
            options={options}
            containerClassName={styles.body}
            value={value}
            onChange={onChange}
        />
    );
};
