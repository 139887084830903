import * as yup from "yup";

export const addUserSchema = yup.object().shape({
    full_name: yup.string().required("Обязательное поле"),
    email: yup
        .string()
        .required("Обязательное поле")
        .email("Некорректный email"),
    password: yup.string().required("Обязательное поле"),
});
