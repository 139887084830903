const defaultState = {
    auth: "first",
    data: false,
    admin: false,
    moderator: false,
    consumer: false,
};

const SET_AUTH = "SET_AUTH";
const SET_USER_DATA = "SET_USER_DATA";

const SET_USER_ADMIN = "SET_USER_ADMIN";
const SET_USER_MODERATOR = "SET_USER_MODERATOR";
const SET_USER_CONSUMER = "SET_USER_CONSUMER";

const LOGOUT_USER = "LOGOUT_USER";

export const userReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_AUTH:
            return { ...state, auth: action.payload };
        case SET_USER_DATA:
            return { ...state, data: action.payload };
        case SET_USER_ADMIN:
            return { ...state, admin: action.payload };
        case SET_USER_MODERATOR:
            return { ...state, moderator: action.payload };
        case SET_USER_CONSUMER:
            return { ...state, consumer: action.payload };
        case LOGOUT_USER:
            return {
                ...state,
                auth: false,
                data: false,
                admin: false,
                moderator: false,
                consumer: false,
            };
        default:
            return state;
    }
};

export const setAuth = (payload) => ({
    type: SET_AUTH,
    payload,
});

export const setUserData = (payload) => ({
    type: SET_USER_DATA,
    payload,
});

export const setUserAdmin = (payload) => ({
    type: SET_USER_ADMIN,
    payload,
});

export const setUserModerator = (payload) => ({
    type: SET_USER_MODERATOR,
    payload,
});

export const setUserConsumer = (payload) => ({
    type: SET_USER_CONSUMER,
    payload,
});

export const logoutUser = () => ({
    type: LOGOUT_USER,
});
