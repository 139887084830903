import styles from "./StockAddresses.module.css";
import { Map } from "../Map";

import { FaMapMarkerAlt } from "react-icons/fa";
import { AiFillClockCircle } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";

export const StockAddresses = ({ addresses, link }) => {
    return (
        <div>
            <p className={styles.title}>Адреса</p>
            <div className={styles.list}>
                {addresses?.map(({ address }) => (
                    <div key={address.id} className={styles.address}>
                        <p className={styles.name}>{address.point_name}</p>
                        <div className={styles.addressItem}>
                            <div className={styles.itemIcon}>
                                <FaMapMarkerAlt color="#559BFB" />
                            </div>
                            <p className={styles.itemText}>{address.address}</p>
                        </div>
                        <div className={styles.addressItem}>
                            <div className={styles.itemIcon}>
                                <AiFillClockCircle color="#E87780" />
                            </div>
                            <p className={styles.itemText}>
                                c {address.work_time_from} до{" "}
                                {address.work_time_to}
                            </p>
                        </div>
                        <div className={styles.addressItem}>
                            <div className={styles.itemIcon}>
                                <BsFillTelephoneFill color="#4DCD2C" />
                            </div>
                            <p className={styles.itemText}>
                                {address.phone_number}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <a href={link} target="_blank" className={styles.link}>
                Перейти на сайт партнера
            </a>
            <Map className={styles.map} places={addresses} />
        </div>
    );
};
