import styles from "./Wysiwyg.module.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import classNames from "classnames";

export const Wysiwyg = ({
    containerClassName,
    label,
    error,
    value,
    onChange,
    onBlur,
}) => {
    return (
        <div className={classNames(styles.container, containerClassName)}>
            {label && <label className={styles.label}>{label}</label>}
            <div className={styles.border}>
                <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </div>
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};
